<template>
  <div class="about">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-2">Marketplace</h1>
        </div>
      </div>
    </div>
    <div class="columns is-multiline">
      <div
          v-for="marketplaceItem in items"
          :marketplaceItem="marketplaceItem"
          :key="marketplaceItem.id"
          class="column is-one-quarter pfdaa-card"
      >
        <router-link :to="`/marketplaceItem/${marketplaceItem.id}`">
          <MarketplaceItemCard :marketplaceItem="marketplaceItem"/>
        </router-link>
      </div>

    </div>
    <div class="post-item">
      <router-link to="/addMarketplaceItem">
        <button class="button is-success">List Your Item</button>
      </router-link>
    </div>
  </div>
</template>
<script>
import MarketplaceService from "../services/marketplaceService"
import {useUserStore} from "../store";
import MarketplaceItemCard from "../components/MarketplaceItemCard";

export default {
  name: "Marketplace",
  components: {MarketplaceItemCard},
  setup() {
    let store = useUserStore()
    return {store}
  },
  data() {
    return {
      items: []
    };
  },
  async mounted() {
    this.items = await MarketplaceService.getAll();
  },

};
</script>
<style lang="scss" scoped>
.main {
  margin-top: 30px;
}

.org-description {
  margin-top: 50px;
}

.hero-body {
  background: #ed213a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
          to right,
          #93291e,
          #ed213a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
          to right,
          #93291e,
          #ed213a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.columns {
  margin: 30px;
}
.post-item{
  margin:40px;
  .button{
    padding:12px;
  }
}
</style>